// Generated by Framer (045d2ed)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {q8VT5JINm: {hover: true}};

const cycleOrder = ["q8VT5JINm"];

const serializationHash = "framer-zaS7j"

const variantClassNames = {q8VT5JINm: "framer-v-1vmnarn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, logo, width, ...props}) => { return {...props, DumU3eAlW: logo ?? props.DumU3eAlW ?? {src: "https://framerusercontent.com/images/zMYc1G4eIBIxBxsXvDHOMxNmHLw.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/zMYc1G4eIBIxBxsXvDHOMxNmHLw.png?scale-down-to=512 512w,https://framerusercontent.com/images/zMYc1G4eIBIxBxsXvDHOMxNmHLw.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/zMYc1G4eIBIxBxsXvDHOMxNmHLw.png 1917w"}, sVXWZ0Nlz: image ?? props.sVXWZ0Nlz ?? {src: "https://framerusercontent.com/images/v7eIxA4E1sH3Jic1GPgZs5Cnl8.jpg?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/v7eIxA4E1sH3Jic1GPgZs5Cnl8.jpg?scale-down-to=512 512w,https://framerusercontent.com/images/v7eIxA4E1sH3Jic1GPgZs5Cnl8.jpg?scale-down-to=1024 1024w,https://framerusercontent.com/images/v7eIxA4E1sH3Jic1GPgZs5Cnl8.jpg 1440w"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};logo?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, sVXWZ0Nlz, DumU3eAlW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "q8VT5JINm", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", pixelHeight: 2204, pixelWidth: 2196, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(sVXWZ0Nlz)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vmnarn", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"q8VT5JINm"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25), 0px 0px 0px 1px rgba(0, 0, 0, 0.1)", rotate: 0, ...style}} variants={{"q8VT5JINm-hover": {boxShadow: "0px 0px 8px 4px rgba(11, 31, 66, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.1)", rotate: 1}}} {...addPropertyOverrides({"q8VT5JINm-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-qe6n8x"} data-framer-name={"Logo Box"} layoutDependency={layoutDependency} layoutId={"h3bUIVssa"}><Image background={{alt: "", fit: "fit", intrinsicHeight: 275.5, intrinsicWidth: 958.5, pixelHeight: 551, pixelWidth: 1917, sizes: "160px", ...toResponsiveImage(DumU3eAlW), ...{ positionX: "center", positionY: "bottom" }}} className={"framer-3iou6j"} data-framer-name={"sushito_logo_white"} layoutDependency={layoutDependency} layoutId={"KrnGYx0ny"}/></motion.div></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zaS7j.framer-1ab75jr, .framer-zaS7j .framer-1ab75jr { display: block; }", ".framer-zaS7j.framer-1vmnarn { cursor: pointer; height: 549px; overflow: hidden; position: relative; width: 422px; will-change: var(--framer-will-change-override, transform); }", ".framer-zaS7j .framer-qe6n8x { align-content: flex-end; align-items: flex-end; bottom: 0px; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: flex-start; left: 0px; overflow: hidden; padding: 32px; position: absolute; right: 0px; z-index: 1; }", ".framer-zaS7j .framer-3iou6j { flex: none; height: 80px; overflow: visible; position: relative; width: 160px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zaS7j .framer-qe6n8x { gap: 0px; } .framer-zaS7j .framer-qe6n8x > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-zaS7j .framer-qe6n8x > :first-child { margin-left: 0px; } .framer-zaS7j .framer-qe6n8x > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 549
 * @framerIntrinsicWidth 422
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"koGUS0z3A":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"sVXWZ0Nlz":"image","DumU3eAlW":"logo"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerdD8eUyJAY: React.ComponentType<Props> = withCSS(Component, css, "framer-zaS7j") as typeof Component;
export default FramerdD8eUyJAY;

FramerdD8eUyJAY.displayName = "Caroussel/CarousselCard";

FramerdD8eUyJAY.defaultProps = {height: 549, width: 422};

addPropertyControls(FramerdD8eUyJAY, {sVXWZ0Nlz: {__defaultAssetReference: "data:framer/asset-reference,v7eIxA4E1sH3Jic1GPgZs5Cnl8.jpg?originalFilename=23_sushito-nieuw_25.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, DumU3eAlW: {__defaultAssetReference: "data:framer/asset-reference,zMYc1G4eIBIxBxsXvDHOMxNmHLw.png?originalFilename=sushito+logo+white.png&preferredSize=auto", title: "Logo", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerdD8eUyJAY, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})